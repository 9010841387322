.difficulty .levelsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.difficulty .levelsList .levelCircle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-style: solid;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    cursor: pointer;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
}

.difficulty .levelsList .levelCircle.blue {
    background-color: var(--blue);
    border-color: var(--blue);
}
.difficulty .levelsList .levelCircle.red {
    background-color: var(--red);
    border-color: var(--red);
}
.difficulty .levelsList .levelCircle.black {
    background-color: black;
    border-color: black;
}