.actionsList {}

.actionsList li {
    margin-bottom: 10px;
    text-align: center;
}

.actionsList li:last-of-type {
    margin-top: 20px;
}

@media only screen and (min-width: 431px) {
    .actionsList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }
    .actionsList li {
        margin: 0 10px;
        flex-basis: 200px;
    }
    .actionsList li:last-of-type {
        flex-basis: 100%;
    }
}