.pageOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.pageOverlay .howItWorks {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.pageOverlay .howItWorks .title {
    font-size: 2rem;
    font-weight: 600;
    text-align: left;
    font-family: 'Roboto Slab', serif;
    margin: 0 0 15px 0;
}

.pageOverlay .howItWorks .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    border: 1px solid var(--gray);
    padding: 5px;
    border-radius: 50%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 30px;
    height: 30px;
    font-family: Arial;
}

.pageOverlay .howItWorks h2 {
    font-size: 1.25rem;
    border-bottom: 1px solid var(--gray);
    padding: 0 0 15px 0;
    margin: 0 0 15px 0;
}

.pageOverlay .howItWorks .body {
    overflow: auto;
}

.pageOverlay .howItWorks .body h3 {
    font-size: 1rem;
    font-weight: 600;
    padding: 0 0 5px 0;
    margin: 0 0 5px 0;
}

.pageOverlay .howItWorks .body h4 {
    margin: 0 0 10px 0;
}

.pageOverlay .howItWorks .body ul {
    margin: 0 0 10px 15px;
    list-style-type: disc;
}

.pageOverlay .howItWorks .body ul li {
    margin: 0 0 5px 0;
}

.pageOverlay .howItWorks .body .levels {
    list-style-type: none;
    margin: 0;
}
.pageOverlay .howItWorks .body .levels li {
    display: flex;
    align-items: center;
}
.pageOverlay .howItWorks .body .levels li:before {
    content: " ";
    display: flex;
    flex: 0 0 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 10px 0 0;
    background: var(--blue);
}
.pageOverlay .howItWorks .body .levels li:nth-child(1):before {
    background: var(--blue);
}
.pageOverlay .howItWorks .body .levels li:nth-child(2):before {
    background: var(--red);
}
.pageOverlay .howItWorks .body .levels li:nth-child(3):before {
    background: #000000;
}

.pageOverlay .howItWorks .body .highlight {
    text-decoration: underline;
    color: var(--blue);
}

.pageOverlay .howItWorks .footer {
    border-top: 1px solid var(--gray);
    padding: 10px 0 0 0;
    text-align: right;
}

.pageOverlay .howItWorks .footer i {
    font-style: italic;
}

@media only screen and (min-width: 431px) {
    .pageOverlay .howItWorks {
        width: 40%;
        height: auto;
        max-height: 80%;
    }
}