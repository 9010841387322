.grid {
    display: flex;
    justify-content: center;
    color: #ffffff;
    font-size: 1rem;
}

.grid.isSolved {
    animation: slide-out-top 2s linear both;
}

.grid .column {}

.grid .column.empty {
    width: 30px;
    flex: 0 0 30px;
}

@-webkit-keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
}
@keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
}

@media only screen and (min-width: 431px) {
    .grid {
        margin: 0;
        font-size: 2rem;
    }
    :global(.isLandscape) :local(.grid) {
        margin: 0 10px;
        font-size: 1rem;
    }
}