.landscapeLock {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: var(--gray);
}

.landscapeLock .inner {
    display: flex;
    flex: 0 0 50%;
    align-items: center;
}

.landscapeLock .inner .logo {
    width: 150px;
    height: 150px;
    border-right: 1px solid #000000;
    padding: 0 30px 0 0;
    margin: 0 25px 0 0;
}

.landscapeLock .inner .logo .phone {
    width: 80px;
    height: 150px;
    border: 1px solid #000000;
    border-radius: 10px;
    position: relative;
}

.landscapeLock .inner .logo .phone:before {
    position: absolute;
    top: 0;
    left: calc((80px - 60px) / 2 - 1px);
    display: block;
    content: "";
    width: 60px;
    border-top: 3px solid #000000;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.landscapeLock .inner .logo .phone:after {
    position: absolute;
    bottom: 5px;
    left: calc((80px - 5px) / 2);
    display: block;
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #000000;
}