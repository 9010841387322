.warmupCountdown h1 {
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
    font-family: 'Roboto Slab', serif;
    margin: 20px 0 0 0;
    color: var(--brown);
}
.warmupCountdown h2 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    font-family: 'Roboto Slab', serif;
    margin: 20px 0 0 0;
}

.warmupCountdown h2 .name {
    color: var(--green);
}

.warmupCountdown .skip {
    display: block;
    margin: 10px auto;
    text-align: center;
}