.line {
    display: flex;
    margin: 10px auto;
    justify-content: center;
    color: #ffffff;
    font-size: 1rem;
}

.line.disabled {
    pointer-events: none;
}

.line .empty {
    width: 30px;
}

@media only screen and (min-width: 431px) {
    .line {
        margin: 0;
        font-size: 2rem;
    }
    :global(.isLandscape) :local(.line) {
        margin: 0 10px;
        font-size: 1rem;
    }
}