@import "reset.css";
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin&family=Roboto+Slab:wght@400;600&family=Roboto:wght@400;500;700&display=swap');

:root {
  --blue: #195687;
  --red: #c70039;
  --green: #42766c;
  --brown: #b19471;
  --gray: #d3d6da;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Libre Franklin', sans-serif;
}

html, body, #root {
  height: 100%;
  min-height: 100%;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  font-size: 16px;
  line-height: 1.2;
  font-family: 'Roboto Slab', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

b {
  font-weight: 600;
}

.button {
  background-color: #ffffff;
  border: 2px solid #000000;
  border-radius: 10px;
  color: #000000;
  padding: 0 15px;
  height: 38px;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto Slab', serif;
}

.button.active {
  background-color: #000000;
  color: #FFFFFF;
}

.button:disabled {
  border: 2px solid var(--gray);
  color: var(--gray);
  cursor: default;
}