.error {
    color: red;
    font-weight: bold;
    margin: 20px;
    width: 100%;
    max-width: 600px;
}

@media only screen and (min-width: 431px) {
    .error {
        margin: 20px auto;
    }
}