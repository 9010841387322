.cell {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    width: 30px;
    height: 30px;
    margin: 2.5px 1.5px;
    border: 2px solid var(--gray);
    border-radius: 25%;
    font-weight: bold;
}

.cell.withCharacter {
    background: var(--brown);
    border-color: var(--brown);
}
.cell.isInCorrectPosition {
    background: var(--green);
    border-color: var(--green);
}
.cell.isFlashing {
    animation-name: flashingRedSquare;
    animation-duration: 900ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.cell.swappable {
    cursor: pointer;
}
.cell.initialSwap {
    animation-name: flashingSquare;
    animation-duration: 900ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes flashingSquare {
    0% {
        background: #ffffff;
        color: var(--brown);
        border-color: var(--gray);
    }
    25% {
        background: var(--brown);
        color: #ffffff;
        border-color: var(--brown);
    }
    50% {
        background: #ffffff;
        color: var(--brown);
        border-color: var(--gray);
    }
    100% {
        background: var(--brown);
        color: #ffffff;
        border-color: var(--brown);
    }
}

@keyframes flashingRedSquare {
    0% {
        background: #ffffff;
        color: var(--red);
        border-color: var(--gray);
    }
    25% {
        background: var(--red);
        color: #ffffff;
        border-color: var(--red);
    }
    50% {
        background: #ffffff;
        color: var(--red);
        border-color: var(--gray);
    }
    100% {
        background: var(--red);
        color: #ffffff;
        border-color: var(--red);
    }
}

/**
 * @see https://stackoverflow.com/questions/17233804/how-to-prevent-sticky-hover-effects-for-buttons-on-touch-devices
 */
@media (hover: hover) {
    .cell.swappable:hover {
        background: #c9aeff;
        border-color: #874cff;
    }
}

@media only screen and (min-width: 431px) {
    .cell {
        width: 60px;
        height: 60px;
        margin: 5px 2.5px;
        border-radius: 15%;
    }
}