.topControls {
    width: 100%;
    padding: 10px 5px;
    margin: 0 0 10px 0;
    border-bottom: 1px solid var(--gray);
}

.topControls .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 1100px;
}

.topControls .inner .left, .topControls .inner .right {
    display: flex;
    align-items: center;
}

.topControls .inner .left .level {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 0 0 5px;
}

.topControls .inner .left .level.level-1 {
    background-color: var(--blue);
}
.topControls .inner .left .level.level-2 {
    background-color: var(--red);
}
.topControls .inner .left .level.level-3 {
    background-color: black;
}

.topControls .inner .left .howItWorks {
    font-size: 30px;
    color: #000000;
    cursor: pointer;
    border-radius: 20px;
}

.topControls .inner .left .contactUs {
    font-size: 1rem;
    width: 30px;
    height: 30px;
    color: #000000;
    cursor: pointer;
    border: 3px solid #000000;
    border-radius: 15px;
    margin: 0 0 0 5px;
    text-align: center;
}
.topControls .inner .left .contactUs .contactUsIcon {
    vertical-align: -4px;
}

.topControls .inner .left .timer {
    margin: 0 0 0 5px;
    min-width: 85px;
    height: 30px;
    font-size: 0.85rem;
}

.topControls .inner .right .strikesLeft {
    display: flex;
    align-items: center;
    line-height: 1;
}

.topControls .inner .right .strikesLeft .strikeTilesList {
    display: flex;
    margin: 0 0 0 5px;
}

.topControls .inner .right .strikesLeft .strikeTilesList .strikeTile {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: var(--gray);
    margin: 0 0 0 5px;
    position: relative;
}

.topControls .inner .right .strikesLeft .strikeTilesList .strikeTile:first-of-type {
    margin: 0;
}

.topControls .inner .right .strikesLeft .strikeTilesList .strikeTile.used:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    right: 0;
    content: "\274c";
    font-size: 10px;
    color: var(--red);
    line-height: 3.1;
    text-align: center;
}

.topControls .inner .right .solve, .topControls .inner .right .reveal, .topControls .inner .right .peek {
    font-size: 0.85rem;
    padding: 0;
    height: 30px;
    width: 90px;
    margin: 0;
}

.topControls .inner .right .reveal {
    margin: 0 0 0 5px;
}

@media only screen and (min-width: 431px) {
    .topControls .inner .left .level {
        width: 40px;
        height: 40px;
        margin: 0 0 0 10px;
    }
    .topControls .inner .left .howItWorks {
        font-size: 40px;
    }
    .topControls .inner .left .contactUs {
        font-size: 25px;
        width: 40px;
        height: 40px;
        border: 4px solid #000000;
        border-radius: 20px;
        margin: 0 0 0 8px;
    }
    .topControls .inner .left .timer {
        margin: 0 0 0 10px;
        font-size: 1rem;
        height: 40px;
    }
    .topControls .inner .right .strikesLeft {
        font-size: 1rem;
    }
    .topControls .inner .right .strikesLeft .strikeTilesList {
        margin: 0 0 0 10px;
    }
    .topControls .inner .right .strikesLeft .strikeTilesList .strikeTile {
        width: 40px;
        height: 40px;
        margin: 0 0 0 10px;
    }
    .topControls .inner .right .strikesLeft .strikeTilesList .strikeTile.used:after {
        font-size: 15px;
        line-height: 2.7;
    }
    .topControls .inner .right .solve, .topControls .inner .right .reveal, .topControls .inner .right .peek {
        font-size: 1rem;
        height: 40px;
        width: 150px;
    }
    .topControls .inner .right .reveal {
        margin: 0 0 0 10px;
    }
}