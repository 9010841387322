.bottomPanel {
    display: flex;
    margin: 20px auto;
    padding: 0;
    justify-content: center;
}

.bottomPanel .control {
    list-style: none;
    margin: 0 5px;
    font-size: 24px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.bottomPanel .control a {
    border-radius: 15px;
    width: 55px;
    height: 55px;
    background: #fff;
    border: 2px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottomPanel .control a .icon {
    color: #000;
    font-size: 1.5rem;
}

.bottomPanel .control:hover a, .bottomPanel .control a:focus, .bottomPanel .control a.active {
    border-color: #d5994f;
}

.bottomPanel .control:has(.playPause) {
    border-left: 2px solid var(--gray);
    padding-left: 10px;
}

.bottomPanel .control .playPause {
    font-size: 30px;
    color: #000000;
    cursor: pointer;
    border-radius: 50%;
    background: var(--gray);
}

@media only screen and (min-width: 431px) {
    .bottomPanel .control {
    }
    .bottomPanel .control a {
        width: 110px;
        height: 110px;
    }
    .bottomPanel .control a:before {
        top: 6px;
        left: 8px;
        right: 20px;
        bottom: 24px;
    }
    .bottomPanel .control a .icon {
        font-size: 2.5rem;
    }
}

@media screen and (orientation:landscape) {
    :global(.isLandscape) :local(.bottomPanel) {
        margin: 5px 0;
        flex-basis: 20%;
    }
    :global(.isLandscape) :local(.bottomPanel .control) {
        width: 30px;
        height: 30px;
    }
    :global(.isLandscape) :local(.bottomPanel .control:has(.playPause)) {
        border-left: none;
        padding-left: 0;
    }
    :global(.isLandscape) :local(.bottomPanel .control a) {
        width: 30px;
        height: 30px;
    }
}