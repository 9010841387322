.errorPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--green);
}

.errorPage h1 {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    font-family: 'Roboto Slab', serif;
    margin: 20px 0;
}
