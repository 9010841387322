.pageOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.pageOverlay .contactUsForm {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.pageOverlay .contactUsForm.thanks {}

.pageOverlay .contactUsForm .title {
    font-size: 2rem;
    font-weight: 400;
    text-align: left;
    font-family: 'Roboto Slab', serif;
    margin: 0 0 15px 0;
}

.pageOverlay .contactUsForm.thanks .title {
    text-align: center;
}

.pageOverlay .contactUsForm .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    border: 1px solid var(--gray);
    padding: 5px;
    border-radius: 50%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 30px;
    height: 30px;
    font-family: Arial;
}

.pageOverlay .contactUsForm input, .contactUsForm textarea {
    font-family: 'Roboto Slab', serif;
    padding: 10px;
    margin: 0 0 10px 0;
    border: 1px solid #000;
    font-size: 1rem;
    resize: none;
}

.pageOverlay .contactUsForm .actions {
    display: flex;
    justify-content: center;
}

.pageOverlay .contactUsForm .actions .button {
    width: 45%;
}

@media only screen and (min-width: 431px) {
    .pageOverlay .contactUsForm {
        width: 40%;
        height: auto;
        max-height: 80%;
    }
}