:global(.button).shareButton {
    display: flex;
    margin: 25px auto 0 auto;
}

.shareButton .icon {
    margin: 0 0 0 5px;
    color: var(--blue);
}

.shareButton .icon.check {
    color: var(--green);
}