.messageBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 7px 10px;
    margin: 0;
    text-align: center;
    font-size: 1.2rem;
}

.messageBox .correct {
    color: var(--green);
}

.messageBox .wrong {
    color: var(--red);
}

.messageBox p {
    margin-bottom: 10px;
}

.messageBox p:last-of-type {
    margin-bottom: 0;
}

.messageBox b {
    font-weight: 600;
}

@media only screen and (min-width: 431px) {
    :global(.isLandscape) :local(.messageBox) {
        display: none;
    }
    :global(.isLandscape) :local(.messageBox .clue) {
        font-size: 1.5rem;
    }
}