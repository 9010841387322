.splashScreen {
    width: 100%;
    min-height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--green);
}

.splashScreen .logo {
    width: 100px;
    height: 100px;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 50%;
}

.splashScreen h1 {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    font-family: 'Roboto Slab', serif;
    margin: 20px 0 0 0;
}

.splashScreen h1 .titleLink {
    text-decoration: none;
    color: #000;
}

.splashScreen h1 .titleLink sup {
    vertical-align: super;
    font-size: 0.5rem;
}

.splashScreen h2 {
    font-size: 1.5rem;
    text-align: center;
    font-family: 'Roboto Slab', serif;
    margin: 20px 0;
}

.splashScreen h3 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    font-family: 'Roboto Slab', serif;
    margin: 20px 0;
}

.splashScreen .chooseDay {
    display: flex;
    justify-content: center;
    align-items: center;
}

.splashScreen .chooseDay select {
    margin: 0 0 0 10px;
    padding: 5px;
}

.splashScreen .levelsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.splashScreen .levelsList .levelCircle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-style: solid;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    cursor: pointer;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
}

.splashScreen .levelsList .levelCircle.blue {
    background-color: var(--blue);
    border-color: var(--blue);
}
.splashScreen .levelsList .levelCircle.red {
    background-color: var(--red);
    border-color: var(--red);
}
.splashScreen .levelsList .levelCircle.black {
    background-color: black;
    border-color: black;
}

@media only screen and (min-width: 431px) {
    .splashScreen .logo {
        width: 250px;
        height: 250px;
    }
    .splashScreen h1 {
        font-size: 2.5rem;
    }
    .splashScreen h2 {
        font-size: 2rem;
    }
}