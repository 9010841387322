.game {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    align-items: stretch;
}

.game .header {
    border-bottom: 1px solid var(--gray);
}
.game .header .headerInner {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    padding: 10px;
}

.game .header .headerInner .titleLink {
    text-decoration: none;
    color: var(--green);
    display: flex;
    flex-direction: column;
    font-size: 0.65rem;
    align-items: center;
    position: relative;
    text-align: center;
}

.game .header .headerInner .titleLink .logo {
    width: 50px;
    margin: 0 0 5px 0;
}

.game .header .headerInner .titleLink sup {
    vertical-align: super;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 0.5rem;
}

.game .header .headerInner h4 {
    border-left: 1px solid var(--gray);
    margin: 0 0 0 10px;
    padding: 0 0 0 10px;
    font-size: 0.95rem;
}

.game .innerWrapper {
    position: relative;
    overflow: hidden;
    margin: auto;
    width: 100%;
    max-width: 1100px;
}
.game .innerWrapper.inSwapMode {
    margin: 0 auto auto auto;
}

.game .innerWrapper .anotherOne {
    display: flex;
    margin: 25px auto 0 auto;
    justify-content: center;
}

.game .footer {
    margin-top: 10px;
    border-top: 1px solid var(--gray);
}
.game .footer.inSwapMode {
    border: none;
}

:global(.isLandscape) :local(.game) {
    flex-direction: row;
    justify-content: space-around;
}

@media only screen and (min-width: 431px) {
    .game .header .headerInner {
        padding: 10px 0;
    }
    .game .header .headerInner h1 {
        font-size: 3rem;
    }
    .game .header .headerInner h4 {
        font-size: 2rem;
    }
}